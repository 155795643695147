import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AdminservicesService {
  private base_url = '';
  constructor(private httpClient: HttpClient) {
    /* if (location.hostname === 'localhost') {
      this.base_url = 'http://anjac-api.college.com:5002';
    } else { */
    this.base_url = 'https://anjac-api.anjaconline.org';
    //}
  }


  private getHeaderOptions(): any {
    const headers: any = {
      'Content-Type': 'application/json'
    };
    const header_options = {
      headers: new HttpHeaders(headers),
      responceType: 'json'
    };
    return header_options;
  }

  async getScrollerMessages(): Promise<any> {
    return this.httpClient
      .get(
        `${this.base_url}/admission-srv/admission/notifications/list`,
        this.getHeaderOptions()
      )
      .toPromise();
  }


}
