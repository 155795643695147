<!-- pageWrapper -->
<div id="pageWrapper">
    <!-- phStickyWrap -->
    <div class="phStickyWrap">
        <!-- pageHeader -->
        <header id="pageHeader" class="py-2 py-lg-4 py-xl-6 headerFixer">
            <div class="container">
                <!-- pageNav -->
                <nav id="pageNav" class="navbar navbar-expand-md navbar-dark border-0 p-0 rounded-0">
                    <!-- logo -->
                    <div class="logo flex-shrink-0 mr-3">
                        <a href="https://admission.anjaconline.org">
                            <img src="assets/logos/logo-lite.png" class="img-fluid" alt="Educa | Landing Page Template">
                        </a>
                    </div>
                    <!-- pageMainNavCollapse -->
                    <div class="collapse navbar-collapse justify-content-end pageMainNavCollapse"
                        id="pageMainNavCollapse">
                        <!-- mainNavigation -->
                        <ul class="navbar-nav mainNavigation fontAlter">
                            <li class="nav-item active">
                                <a class="nav-link" href="https://admission.anjaconline.org">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://admission-portal.anjaconline.org/login">Login</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"
                                    href="https://admission-portal.anjaconline.org/register">Register</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link" href="assets/docs/How-to-apply-ANJAC-2021.pdf">How to Apply?</a>
                            </li> -->

                        </ul>
                    </div>
                    <!-- pageNavOpener -->
                    <button class="navbar-toggler pageNavOpener position-relative" type="button" data-toggle="collapse"
                        data-target="#pageMainNavCollapse" aria-controls="pageMainNavCollapse" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </nav>
            </div>
        </header>
    </div>
    <main>

        <!-- scrollToIntroBlock -->
        <div id="scrollToIntroBlock" class="mt-n12 pt-12 mt-lg-n16 pt-lg-16 mt-xl-n20 pt-xl-20">
            <!-- introBlock -->
            <section class="introBlock d-flex w-100 bgCover text-center position-relative text-white"
                style="background-image: url(assets/images/college_home.jpg);">
                <div class="alignHolder w-100 d-flex align-items-center">
                    <div class="align w-100 pt-20 pt-md-24 pt-lg-30 pt-xl-38 pb-3 pb-md-10">
                        <div class="container position-relative">

                            <div class="mxterWrap mx-auto">
                                <div class="wow fadeInDown">
                                    <h1 class="text-white text-uppercase mb-3">
                                        <span class="d-block headingTitle mb-1"
                                            style="text-shadow: 0 0 4px dimgrey;">Learn with us</span>
                                        <span class="d-block" style="text-shadow: 0 0 4px dimgrey;">ADMISSION
                                            2024</span>
                                    </h1>
                                </div>
                                <div class="wow fadeInDown" data-wow-delay="0.5s" style="text-shadow: 0 0 4px dimgrey;">
                                    <p>To become disciplined, competent, socially committed and morally upright
                                        intellectuals through
                                        high quality education.</p>
                                </div>
                                <a href="https://admission-portal.anjaconline.org/register"
                                    class="btn btnTheme position-relative border-0 p-0 mt-4 wow bounceIn"
                                    data-wow-delay="1s" data-hover="Apply Now">
                                    <span class="d-block btnText">Apply Now</span>
                                </a>
                                <a href="https://admission-portal.anjaconline.org/login"
                                    class="btn btnTheme position-relative border-0 p-0 mt-4 wow bounceIn"
                                    data-wow-delay="1s" data-hover="Login Now">
                                    <span class="d-block btnText"
                                        style="    background-color: #19b533;border-color: #19b533;">Login Now</span>
                                </a>



                            </div>
                            <div class="row" style="margin-top: 30px;">
                                <div class="col-lg-12">
                                    <marquee scrolldelay=300 direction="up" style="height:300px">

                                        <div class="list-group">

                                            <!-- <a href="#"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Entrance Examination: For B.Com. (Professional Accounting) will
                                                        be held on 22.06.2022 at 11.00 AM in the College M.C.A.
                                                        Laboratory
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="assets/docs/Guidelines_to_B_Com_PA-Entrance_new.pdf"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Guidelines for B.Com (PA) Entrance Examination - Click Here to
                                                        view
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="#"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        B.Sc. (PHS) Endurance Examination will be conducted at the
                                                        College Play Ground on 27.06.2022 at 8.00 AM and report on
                                                        time with your Medical Fitness certificate, all original
                                                        certificates and Sports kit.
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="assets/docs/3-PHS-Medical-Fitness-Certificate.pdf"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Medical Fitness Certificate - PHS applicants
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="#"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        After the +2 results announcement, All UG Programme applicants
                                                        must enter your +2 marks in the admission portal
                                                        immediately
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="#"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        All UG Programme Selection list will be announced on 23.06.2022
                                                        (Thursday)
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="#"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Entrance Examination: For all PG Programme (Except MCA) will be
                                                        held on 04.07.2022 at 10.30 AM
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="assets/docs/7-Refund-Message.pdf"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Refund Policy
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>
                                            <a href="assets/docs/8-Rules-Regulations-Hostel-Boys.pdf"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Rules and Regulations for Boys Hostel
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>
                                            <a href="assets/docs/9-Rules-Regulations-Hostel-Girls.pdf"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Rules and Regulations for Girls Hostel
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="#"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Bus Transportation Facility available for both Boys & Girls
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="assets/docs/AJ_Web_Brochure.pdf"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        ANJAC Prospectus
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>


                                            <a href="assets/docs/How_to_apply_ANJAC.pdf"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Guidelines to apply for UG & PG Programmes
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="assets/docs/regular-flow.pdf"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Admission Procedure for Aided Programmes
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a>

                                            <a href="assets/docs/self-financing-flow.pdf"
                                                class="list-group-item list-group-item-action flex-column align-items-start active">
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">
                                                        Admission Procedure for Self Financed Programmes
                                                    </h5>
                                                    <small></small>
                                                </div>
                                            </a> -->
                                            <ng-container *ngFor="let message of scroller_messages; let i = index;">
                                                <a [href]="message.link!=''?message.link:'#'"
                                                    class="list-group-item list-group-item-action flex-column align-items-start active"
                                                    *ngIf="message.is_active">
                                                    <div class="d-flex w-100 justify-content-between">
                                                        <h5 class="mb-1">
                                                            {{message.message}}
                                                        </h5>
                                                        <small></small>
                                                    </div>
                                                </a>
                                            </ng-container>

                                        </div>

                                    </marquee>


                                </div>
                            </div>

                            <div class="position-relative catsWrap mx-auto mt-6 mt-md-0 wow flipInX"
                                data-wow-delay="1.5s">
                                <strong class="arrowTag arrowTag01 fontThird mb-4 mb-md-0 d-block"
                                    style="text-shadow: 0 0 4px dimgrey;">

                                </strong>
                                <div class="row d-block mt-md-14">

                                    <div class="row d-block wow fadeInDown" data-wow-duration="2s" data-wow-delay="2s">
                                        <div class="studentsSaySlider">

                                            <div>
                                                <div class="col-12">
                                                    <!-- <div class="bg-news d-flex flex-wrap "
                                                        style="background-color: rgba(255, 255, 255, 0.413);padding:10px">
                                                        <q style="text-shadow: 0 0 4px dimgrey;">

                                                            For B.Com. (Professional Accounting), Entrance
                                                            Examination will be conducted on 2.08.2021 between 2:00
                                                            PM and 3:00 PM through Online

                                                        </q>
                                                        <cite>
                                                            <strong class="h d-block h3 mb-1"
                                                                style="text-shadow: 0 0 4px dimgrey;">

                                                            </strong>
                                                        </cite>
                                                    </div> -->
                                                    <!-- <blockquote class="sayQuote position-relative pl-10">
                                                        <div class="bg-news d-flex flex-wrap "
                                                            style="background-color: rgba(255, 255, 255, 0.413);padding:10px">
                                                            <img src="assets/images/admission_brouchure.jpeg"
                                                                width="100%"
                                                                style="height: 300px !important; cursor:pointer">
                                                        </div>


                                                    </blockquote> -->
                                                </div>
                                            </div>

                                            <div>
                                                <div class="col-12">
                                                    <!-- <blockquote class="sayQuote position-relative pl-10">

                                                        <div class="bg-news d-flex flex-wrap "
                                                            style="background-color: rgba(255, 255, 255, 0.413);padding:10px">
                                                            <q style="text-shadow: 0 0 4px dimgrey;">

                                                                ANJAC Portal Login Helper.
                                                                <a href="assets/docs/anjac_portal_login_helper.pdf"
                                                                    target="blank" style="color: blue;">Click here to
                                                                    view helper file</a>
                                                            </q>

                                                            <q style="text-shadow: 0 0 4px dimgrey;">

                                                                Bus Fee Payment Helper File.
                                                                <a href="assets/docs/bus_fee_helper.pdf" target="blank"
                                                                    style="color: blue;">Click here to
                                                                    view helper file</a>
                                                            </q>

                                                            <q style="text-shadow: 0 0 4px dimgrey;">

                                                                Hostel Fee Payment Helper File.
                                                                <a href="assets/docs/hostel_fee_helper.pdf"
                                                                    target="blank" style="color: blue;">Click here to
                                                                    view helper file</a>
                                                            </q>

                                                            <cite>
                                                                <strong class="h d-block h3 mb-1"
                                                                    style="text-shadow: 0 0 4px dimgrey;">

                                                                </strong>
                                                            </cite>
                                                        </div>
                                                    </blockquote> -->
                                                </div>
                                            </div>





                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- scrollToAboutUs -->
        <div id="scrollToAboutUs" class="mt-n12 pt-12 mt-lg-n16 pt-lg-16 mt-xl-n20 pt-xl-20">
            <!-- aboutUsBlock -->
            <article class="aboutUsBlock pt-7 pt-md-15 pt-lg-20 pt-xl-29">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 order-md-2 wow slideInRight" data-wow-delay="0.5s">
                            <!-- headingHead -->
                            <header class="headingHead hasPattern mb-6 mb-xl-9 position-relative pb-4">
                                <h2 class="mb-4">
                                    <!-- <strong class="font-weight-normal d-block headTitle mb-2">About Us</strong> -->
                                    <span class="d-block">About Us</span>
                                </h2>
                                <!-- <p>This should be used to tell a story and let your users know a little more about your product or service. How can you benefit them?</p> -->
                            </header>

                            <!-- featuresList -->
                            <ul class="list-unstyled featuresList ">
                                <li style="width: 100%;">Focussing on the holistic development of students in consonance
                                    with the
                                    dynamics of local and global needs</li>
                                <li style="width: 100%;">Inculcating human values like truth, compassion, empathy,
                                    universal brotherhood
                                    perseverance and ethics to supplement the core competencies of the learners</li>
                                <li style="width: 100%;">Enhancing the knowledge and skills of the staff through Faculty
                                    Development
                                    Programmes</li>
                                <li style="width: 100%;">Promoting the research ethos among the students and faculty
                                    members to cater the
                                    needs of the society</li>
                                <li style="width: 100%;">Providing care and autonomy to ensure people’s dignity and
                                    respect</li>
                                <li style="width: 100%;">Empowering the students by offering training on
                                    ‘Entrepreneurship Skills’ to
                                    emerge as ‘job providers’ rather than job seekers</li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-6 order-md-1 d-flex align-items-end wow slideInLeft"
                            data-wow-delay="0.5s">
                            <!-- imgHolder  -->
                            <div class="imgHolder position-relative mt-4 mt-md-n9 mx-auto mx-md-0">
                                <span class="wrap overflow-hidden d-block ml-11 ml-lg-21">
                                    <img src="assets/images/img02.jpg" class="img-fluid" alt="image description">
                                </span>
                                <span class="elemsWrap position-absolute">
                                    <img src="assets/images/pattern01.png" class="img-fluid" alt="image description">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>

        <!-- completionBlock -->
        <section
            class="completionBlock position-relative bg-dark text-white pt-12 pb-2 pt-md-22 pb-md-22 overflow-hidden">
            <div class="container">
                <div class="row">

                    <div class="col-12 col-lg-12 d-flex wow slideInRight">
                        <!-- countListItems -->
                        <ul
                            class="list-unstyled countListItems d-flex flex-wrap align-items-center flex-grow-1 pt-md-16">
                            <li>
                                <!-- countColumn -->
                                <div class="countColumn type1">
                                    <h2 class="fontFourth mb-5"><span>5297</span>+</h2>
                                    <h3>Students</h3>
                                    <!-- <p>This should be used to tell a story and let your users know more about your service. How can you benefit them?</p> -->
                                </div>
                            </li>
                            <li>
                                <!-- countColumn -->
                                <div class="countColumn type2">
                                    <h2 class="fontFourth mb-5"><span>90</span>%</h2>
                                    <h3>Placement</h3>
                                    <!-- <p>This should be used to tell a story and let your users know more about your service. How can you benefit them?</p> -->
                                </div>
                            </li>
                            <li>
                                <!-- countColumn -->
                                <div class="countColumn type3">
                                    <h2 class="fontFourth mb-5"><span>260</span>+</h2>
                                    <h3>Faculties (Ph.D - 131, SET - 24, NET - 7)
                                    </h3>
                                    <!-- <p>This should be used to tell a story and let your users know more about your service. How can you benefit them?</p> -->
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <span class="bgMap position-absolute inaccessible">
                <img src="assets/images/img07.png" class="img-fluid" alt="map">
            </span>
            <span class="ronder rouder1 inaccessible position-absolute">
                <img src="assets/images/pattern05.png" alt="image">
            </span>
            <span class="ronder rouder2 inaccessible position-absolute">
                <img src="assets/images/pattern06.png" alt="image">
            </span>
        </section>

    </main>
    <!-- ftAreaWrap -->
    <div class="ftAreaWrap bg-light">
        <!-- pageFooter -->
        <footer id="pageFooter" class="text-center text-md-left pb-3 pt-md-4 pb-lg-5 pt-xl-6 pb-xl-6 position-relative">
            <div class="container">
                <div class="row">

                    <div class="col-6 order-md-3">

                        <div class="col-12 order-md-1 position-static">

                            <!-- copyright -->
                            <div class="fontAlter">
                                <p><strong class="fwSemi">{{year}} &copy; <a href="https://anjaconline.org">ANJAC</a>.
                                        All
                                        rights reserved.</strong></p>
                            </div>
                        </div>
                    </div>

                    <div class="col-6 order-md-3">

                        <div class="col-12 order-md-1 position-static">

                            <div class="fontAlter">
                                <p><strong class="fwSemi">Developed and maintained by
                                        <a href="https://cooldreamers.com" target="_blank"
                                            style="text-decoration: underline; color: #272063 !important;">Cool
                                            Dreamers</a>
                                    </strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>