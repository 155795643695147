import { Component, OnInit } from '@angular/core';
import { AdminservicesService } from './services/rest/adminservices.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'anjac-admission-landing-ui';
    scroller_messages: ScrollerMessagesInterface[] = [];
    year = new Date().getFullYear();
    ngOnInit(): void {
        this.getScrollerMessages();
    }

    async getScrollerMessages() {
        try {
            const service_response = await this.restService.getScrollerMessages();
            if (service_response && service_response.success) {
                this.scroller_messages = service_response.data;
            }
        } catch (error) {
            alert('Error while read data');
        } finally {
        }
    }
    constructor(private restService: AdminservicesService) {
    }
}


interface ScrollerMessagesInterface {
    message: string,
    link?: string,
    is_active: boolean,
    priority_no: number
}